/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Component */
import SectionTitle from '@components/Headings/SectionTitle';
import StyleConfig from '@components/UI/Common/StyleConfig';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SubscribeContent from '@components/UI/Subscribe/SubscribeContent';
/* Services  */
/* Styles */
/**
 * Renders Expression Component
 * @param {Object} sectionContent - data for the section from api
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 */

const SadhguruQuotes = ({ section, language, region }) => {
  const secContent = section?.sectionContent;
  // console.log('region', region);

  return (
    <>
      {secContent && (
        <Box as="section" className="page-wrap container-article">
          <SectionTitle titleObj={secContent[0]} />
          <StyleConfig
            id={section?.sectionId}
            key={section?.sectionId}
            style={section?.styleConfig}
            mb="30px"
            pb="0"
          >
            <Box
              display={{ base: 'block', md: 'flex' }}
              mb="20px"
              alignContent="center"
            >
              <Box
                // w={{ base: '100%', md: '40%', lg: '40%' }}
                textAlign={{ base: 'center', md: 'right' }}
                mb={{ base: '20px', md: '0' }}
                pl={{ base: '0', md: '20px' }}
              >
                <LazyLoadImageComponent
                  src={secContent[2]?.image?.url}
                  title="Sadhguru Quotes"
                  alt="Sadhguru Quotes"
                />
              </Box>
              <Box
                // w={{ base: '100%', md: '50%', lg: '55%' }}
                display="inline-flex"
                // alignItems="center"
              >
                <SubscribeContent
                  contentType="quotes"
                  region={region}
                  contentObj={secContent[1]}
                  pType="dailysubscription"
                />
              </Box>
            </Box>
          </StyleConfig>
        </Box>
      )}
    </>
  );
};

export default SadhguruQuotes;
