/* Built In Imports */
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import throttle from 'lodash/throttle';

/* Internal Imports */
/* Components */
import MediaArticleCard from '@components/Card/MediaArticleCard';
import QuoteCard from '@components/Card/QuoteCard';
import WisdomFilter from '@components/Filters/WisdomFilter';
import WisdomBanner from '@components/HeaderBanner/WisdomBanner';
import Breadcrum from '@components/Navigation/Breadcrum';
import FeaturedPrograms from '@components/UI/FeaturedPrograms';
import SadhguruQuotes from '@components/UI/SadhguruQuotes';
import HeadComponent from '@components/Utility/HeadComponent';
import Loader from '@components/Utility/Loader';
import { AuthContext } from '@store/auth-context';

/* Services */
import { fetchDetails } from 'services/quotesubscriptionService';
import {
  fetchWisdomBlogSearchData,
  fetchWisdomLandingPageData,
} from 'services/wisdomService';

/* Styles */
import Masonry from 'react-masonry-css';

/**
 *
 * @param pageData.pageData
 * @param {object} pageData data
 * @param {string} section
 * @param {object} wisdomTopics wisdom Topics
 * @param {string} urlpath url
 * @returns {ReactElement} WisdomBlog component
 */
export default function WisdomBlog({
  pageData,
  type,
  section,
  wisdomTopics,
  urlpath,
  reg,
  lang,
}) {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    980: 2,
    768: 2,
    736: 1,
  };
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const [quoteData, setQuoteData] = useState('');

  const { region, language, ...neededQueryParams } = router.query;
  const [posts, setPosts] = useState(pageData?.posts);
  const [cards, setCards] = useState(pageData?.posts?.cards);
  const [cardIds, setCardIds] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoaderForMore, setIsLoaderForMore] = useState(false);
  const { topic, contentType, sort_bef_combine, combine, page } = router.query;
  const sortby = sort_bef_combine ? 'oldest' : 'newest';
  const [isRefresh, setIsRefresh] = useState(false);

  // adding isLiked & isBookmarked for every card
  // const setLikeAndBookmarkForCard = useCallback(
  //   async (id, flag) => {
  //     if (cards && cards.length > 0) {
  //       let likes = [];
  //       let bookmarks = [];

  //       if (isLoggedIn) {
  //         const ids = cards.map(el => el.uuid);
  //         const likesBookmarks = await getLikesAndBookmarks(ids);
  //         likes = likesBookmarks.likes;
  //         bookmarks = likesBookmarks.bookmarks;
  //       }

  //       const updatedCards = cards.map(el => {
  //         if (id && flag && el.uuid === id) {
  //           return {
  //             ...el,
  //             isLiked: flag === 'likes' ? !el.isLiked : el.isLiked,
  //             isBookmarked:
  //               flag === 'bookmarks' ? !el.isBookmarked : el.isBookmarked,
  //           };
  //         }
  //         return {
  //           ...el,
  //           isLiked:
  //             likes && likes.find(like => like === el.uuid) ? true : false,
  //           isBookmarked:
  //             bookmarks && bookmarks.find(bookmark => bookmark === el.uuid)
  //               ? true
  //               : false,
  //         };
  //       });
  //       setCards(updatedCards);
  //     }
  //     return null;
  //   },
  //   [cards, isLoggedIn]
  // );

  const showMoreCards = async () => {
    setIsLoaderForMore(true);
    let moreCards = [];

    if (combine) {
      moreCards = await fetchWisdomBlogSearchData({
        // region,
        language: lang,
        contentType,
        searchKey: combine,
        start: cards.length,
        slug: router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog',
      });
    } else {
      moreCards = await fetchWisdomLandingPageData({
        // region,
        lang: lang,
        start: page ? page * 12 : cards.length,
        topic,
        contentType,
        sortby,
        slug: router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog',
      });
    }

    if (moreCards.posts.cards.length >= 0) {
      setIsLoaderForMore(false);
    }
    const oldCards = isRefresh ? cards : [];
    setIsRefresh(false);
    setCards([...oldCards, ...moreCards.posts.cards]);
    const moreIds = moreCards.posts.cards.map(el => el.uuid);
    setCardIds([...cardIds, ...moreIds]);
    // setLikeAndBookmarkForCard();
  };

  const fetchFilteredData = useCallback(
    async ({ searchKey, topic, contentType, sortby }) => {
      setIsLoader(true);
      let cards = [];
      if (searchKey) {
        cards = await fetchWisdomBlogSearchData({
          // region,
          language: lang,
          contentType,
          sortby,
          searchKey: searchKey,
          slug: router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog',
        });
      } else {
        cards = await fetchWisdomLandingPageData({
          // region,
          lang: lang,
          topic: topic,
          contentType: contentType,
          sortby: sortby,
          slug: router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog',

          // slug: 'wisdom/blog',
        });
      }

      if (cards.posts?.cards.length >= 0) {
        setIsLoader(false);
      }
      setPosts(cards?.posts);
      setCards(cards?.posts?.cards);
    },
    [lang, reg]
  );

  const updateCards = (id, flag) => {
    // setLikeAndBookmarkForCard(id, flag);
  };

  const getPath = () => {
    let path;
    if (router.asPath.indexOf('/type') > -1) {
      path = `/${lang}/${
        router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog'?.toLowerCase()
      }/type/${contentType}`;
    } else {
      path = `/${lang}/${
        router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog'?.toLowerCase()
      }`;
    }
    return path;
  };

  const clickHandler = () => {
    const path = getPath();
    router.push(
      {
        pathname: path,
        query: {
          ...neededQueryParams,
          page: page ? Number(page) + 1 : 1,
        },
      },
      null,
      { scroll: false }
    );
    setIsRefresh(true);
  };

  const throttled = throttle(clickHandler, 100);

  useEffect(() => {
    setIsLoggedIn(authContext.isLoggedIn);
  }, [authContext.isLoggedIn, isLoggedIn]);

  useEffect(() => {
    router.isReady &&
      fetchFilteredData({
        topic,
        contentType,
        sortby,
        searchKey: combine,
      });
  }, [reg, lang, combine, contentType, sortby, topic]);

  useEffect(() => {
    page > 0 && showMoreCards();
  }, [page]);

  useEffect(() => {
    localData();
  }, [reg, lang]);

  const localData = async () => {
    const quoteData = await fetchDetails(reg, lang);
    const section = quoteData?.body?.sectionContent;
    const secContent = section;
    setQuoteData(secContent);
  };

  // console.log('authContext.region', reg, lang);
  return (
    <Box pos="relative" float="left" w="100%">
      <HeadComponent
        data={pageData?._seoMetaTags}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box visibility={{ base: 'hidden', md: 'unset' }}>
        <Breadcrum
          page={pageData?.breadcrumbs}
          alsoin={pageData?.alsoin}
          url={pageData?.url}
          region={authContext.region}
          lang={lang}
          pageConfig={pageData?.pageConfig}
        />
      </Box>
      {/* wisdom banner section */}
      <Box pos="relative" float="left" w="100%">
        <WisdomBanner title={section} />
      </Box>
      {/* filter section */}
      <WisdomFilter
        featuredTopics={pageData?.featuredTopics || []}
        type={type}
        language={lang}
        region={authContext.region}
        contentTypes={pageData?.contentTypes || []}
        fetchFilteredData={fetchFilteredData}
        wisdomTopics={wisdomTopics}
        urlpath={urlpath}
        modalTopics={pageData?.allTopics || []}
        popularTopics={pageData?.popularTopics || []}
        locales={pageData?.locales || []}
      />
      {/* view content section */}
      <Box
        pos="relative"
        float="left"
        w="100%"
        mt="5vw"
        textAlign="center"
        mb="8vw"
      >
        <Box
          m="0 auto"
          p={{ base: '0px 0 0 0', lg: '0px 10px 0 0' }}
          pos="relative"
          maxW="1165px"
          left={{ base: '0' }}
        >
          {/* <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1100: 3 }}
          > */}
          <Masonry breakpointCols={breakpointColumnsObj}>
            {cards &&
              cards.length > 0 &&
              cards.map((post, i) => {
                if (post.contentType === 'quotes') {
                  return (
                    <Box
                      key={i}
                      mx="auto"
                      maxW={{ base: '302px', md: '330px', lg: '370px' }}
                    >
                      <QuoteCard
                        key={post.uuid}
                        {...post}
                        reg={authContext.region}
                        lang={lang}
                        updateCards={updateCards}
                      />
                    </Box>
                  );
                }
                if (post.contentType) {
                  return (
                    <Box
                      key={i}
                      mx="auto"
                      maxW={{ base: '302px', md: '330px', lg: '370px' }}
                    >
                      <MediaArticleCard
                        key={i}
                        {...post}
                        lang={lang}
                        updateCards={updateCards}
                      />
                    </Box>
                  );
                }
              })}
          </Masonry>
          {/* </ResponsiveMasonry> */}
          {posts?.totalCount > cards?.length && cards?.length > 11 && (
            <Link
              display="block"
              width="170px"
              p="10px 36px"
              border="none"
              borderRadius="3px"
              bgColor="#cf4520"
              color="#faf7f0"
              fontSize="18px"
              fontFamily="FedraSansStd-book"
              textAlign="center"
              m="0 auto"
              mt="30px"
              _hover={{ textDecoration: 'none', bgColor: '#000054' }}
              onClick={throttled}
              title="Go to next section"
              rel="next"
            >
              Load More
            </Link>
          )}
          {router.isReady &&
            cards?.length === 0 &&
            !isLoaderForMore &&
            !isLoader && (
              <Box
                color="#28231e"
                mb="2vw"
                fontSize="18px"
                lineHeight="1.7"
                maxW="70%"
                m="0 auto"
              >
                We're sorry. There are no matching results for your
                selection.Please widen your preferences to view results
              </Box>
            )}
          {isLoaderForMore && (
            <Box pos="relative">
              <Loader />
            </Box>
          )}
        </Box>
        {isLoader && <Loader />}
      </Box>
      {contentType === 'quotes' && (
        <SadhguruQuotes
          section={quoteData}
          region={authContext.region}
          language={lang}
        />
      )}
      <Box maxW="1300px" m="0 auto" w="100%">
        {pageData?.relatedPrograms?.length > 0 && (
          <FeaturedPrograms
            programs={pageData?.relatedPrograms[0]}
            region={authContext.region}
            lang={lang}
          />
        )}
      </Box>
    </Box>
  );
}
