/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Input, Text, useBreakpointValue } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Loader from '@components/Utility/Loader';
import config from '@config';
import { LangStyle } from 'constants/index';
import Filter from './Filter';

/* Services */
const AllTopicsModal = dynamic(() => import('../UI/Wisdom/AllTopicsModal'));

/**
 *
 * @param featuredTopics
 * @param contentTypes
 * @param urlpath
 * @param modalTopics
 * @param popularTopics
 * @param locales
 * @param type
 */
export default function WisdomFilter({
  featuredTopics,
  contentTypes,
  urlpath,
  modalTopics,
  popularTopics,
  locales,
  type,
  region,
  language
}) {
  const ref = useRef();
  const router = useRouter();
  const variant = useBreakpointValue({ base: 'small', lg: 'large' });
  const [isLoader, setIsLoader] = useState(false);
  const [allTopics, setAllTopics] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { sort_bef_combine, combine, page, ...queryParams } =
    router.query;
  const { topic, contentType } = queryParams;
  const neededQueryParams = {
    ...(topic && { topic }),
    ...(contentType && { contentType }),
  };
  const section = router.asPath.indexOf('/wisdom') > -1 ? 'wisdom' : 'blog';
  let params = Object.keys(neededQueryParams);

  useEffect(() => {
    !combine && ref && ref.current && (ref.current.value = '');
  }, [region, language, combine, contentType, topic]);

  const getSearchKey = () => {
    const { combine } = router.query;
    return combine;
  };

  const generateTopicList = () => {
    const topicList = featuredTopics?.map(topic => {
      return {
        option: topic?.title,
        value: topic?.urlAlias,
      };
    });
    topicList.push({
      option: 'See All Topics',
      value: 'all',
    });
    return topicList;
  };

  const onLanguageChange = data => {
    const newLang = data.value.split('_')[0];
    router.push(
      {
        pathname: `/${newLang}/${section}`,
      },
      null,
      { scroll: false }
    );
  };

  const clearAllHandler = () => {
    setIsLoader(true);
    router
      .push(
        {
          pathname: `/${language}/${section}`,
        },
        null,
        { scroll: false }
      )
      .then(() => {
        setIsLoader(false);
        ref && ref.current && (ref.current.value = '');
      });
  };

  const fetchDataHandler = ({ data, searchKey }) => {
    if (data && data.value === 'all') {
      setAllTopics(true);
    } else {
      setIsLoader(true);
      const reqParams = { ...neededQueryParams };
      searchKey && delete reqParams?.topic;
      searchKey
        ? router
          .push(
            {
              pathname: `/${language}/${section}`,
              query: {
                ...reqParams,
                combine: searchKey,
                ...(data?.value && { [data.type]: data.value }),
              },
            },
            null,
            { scroll: false }
          )
          .then(() => {
            setIsLoader(false);
          })
        : (params?.length === 0 ||
          (params?.length === 1 && params.includes('contentType'))) &&
          data?.type === 'contentType' &&
          section === 'wisdom'
          ? router
            .push(
              {
                pathname: `/${language}/${section}/type/${data.value}`,
              },
              null,
              { scroll: false }
            )
            .then(() => {
              setIsLoader(false);
              ref && ref.current && (ref.current.value = '');
            })
          : router
            .push(
              {
                pathname: `/${language}/${section}`,
                query: {
                  ...neededQueryParams,
                  ...(data?.value && { [data.type]: data.value }),
                },
              },
              null,
              { scroll: false }
            )
            .then(() => {
              setIsLoader(false);
              ref && ref.current && (ref.current.value = '');
            });
    }
    // fetchFilteredData(data);
  };

  const deleteTag = data => {
    setIsLoader(true);
    for (const key in neededQueryParams) {
      if (neededQueryParams[key] == data[key]) {
        delete neededQueryParams[key];
      }
    }
    // Please don't move this below line to top of function.
    params = Object.keys(neededQueryParams);
    params.includes('contentType') && section === 'wisdom'
      ? router
        .push(
          {
            pathname: `/${language}/${section}/type/${neededQueryParams['contentType']}`,
          },
          null,
          { scroll: false }
        )
        .then(() => {
          setIsLoader(false);
          ref && ref.current && (ref.current.value = '');
        })
      : router
        .push(
          {
            pathname: `/${language}/${section}`,
            query: {
              ...neededQueryParams,
            },
          },
          null,
          { scroll: false }
        )
        .then(() => {
          setIsLoader(false);
        });
  };

  const closeModal = () => {
    setAllTopics(false);
  };

  const goToSearch = () => {
    console.log('ref.current.value0, 0', ref.current.value);
    router
      .push(`/${language}/wisdom/search?q=${ref.current.value}`);
  };

  const searchInputFilter = (
    <Box
      position={{ base: 'relative' }}
      right={{ base: 'inherit', xl: 'inherit' }}
      top={{ base: 'unset', md: '5px', xl: 'unset' }}
      padding="10px 0 !important"
      ml="15px !important"
      minWidth={{ base: '250px', md: '275px', xl: '250px' }}
      borderWidth="0 0 1px 0 !important"
      fontFamily="FedraSansStd-book"
      fontSize="16px"
      borderColor="#CF4523 !important"
      outline="unset"
      lineHeight="1.42857143"
      cursor="pointer"
      userSelect="none"
      w={{ base: 'auto', sm: '95%', lg: 'auto' }}
      _hover={{
        color: '#cf4520',
      }}
      order={{
        base: '3',
        sm: '3',
        md: '1',
        xl: '1',
      }}
      display={{ base: 'inherit', sm: 'inline-block', xl: 'inherit' }}
    >
      <Input
        ref={ref}
        defaultValue={combine}
        variant="unstyled"
        pl="5px"
        placeholder="Search"
        // onKeyPress={e => {
        //   if (e.code == 'Enter') {
        //     fetchDataHandler({
        //       data: {
        //         type: 'contentType',
        //         option: type || neededQueryParams.contentType,
        //         value: type || neededQueryParams.contentType,
        //       },
        //       searchKey: e.target.value,
        //     });
        //   }
        // }}
        onKeyPress={e => {
          if (e.code == 'Enter') {
            goToSearch();
          }
        }}
        color="#333333"
        _placeholder={{
          color: '#333333',
        }}
      ></Input>
      <Text
        as="span"
        background={`url('${config.imgPath}/d/46272/1663582866-search.svg') no-repeat`}
        // background="url(search.svg') no-repeat"
        backgroundSize="auto auto"
        width="24px"
        height="24px"
        cursor="pointer"
        position="absolute"
        margin="0"
        borderWidth="0 !important"
        float="right"
        right="0"
        // onClick={e =>
        //   fetchDataHandler({
        //     data: {
        //       type: 'contentType',
        //       option: type || neededQueryParams.contentType,
        //       value: type || neededQueryParams.contentType,
        //     },
        //     searchKey: ref?.current?.value,
        //   })
        // }
        onClick={e => {
          goToSearch();
        }}
      ></Text>
    </Box>
  );
  return (
    <Box
      backgroundColor="#fff"
      borderWidth="0"
      maxWidth="1300px"
      margin="0 auto"
      minHeight="auto"
    >
      {isLoader && <Loader />}
      <Box
        float="left"
        width="100%"
        boxShadow="0 0 14px 0 rgb(0 0 0 / 10%)"
        maxWidth="1300px"
      >
        {variant !== 'large' && (
          <Box mt="20px" display={{ base: 'block', lg: 'none' }} w="96%">
            {searchInputFilter}
          </Box>
        )}
        <Box
          display="flex"
          p={{ base: '25px 4% 25px', md: '25px 5% 0', xl: '25px 5% 12px' }}
          justifyContent="start"
          margin="0px 0px 20px"
          flexDirection={{
            base: 'row',
            xl: LangStyle[language]?.flexRowRTL
              ? LangStyle[language].flexRowRTL
              : 'row',
          }}
          flexWrap={{ base: 'wrap', xl: 'nowrap' }}
        >
          <Box
            display={{ base: 'block', xl: 'flex' }}
            m={{ base: '30px 0 0 0', md: '0 0 15px', xl: '0' }}
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              xl: LangStyle[language]?.flexRowRT
                ? LangStyle[language].flexRowRTL
                : 'row',
            }}
            order={{ base: '2', xl: 'unset' }}
            w={{ base: '100%', xl: 'unset' }}
          >
            {variant === 'large' && language == 'ar' && (
              <Box
                display={{
                  base: 'none',
                  lg: language == 'ar' ? 'block' : 'none',
                }}
              >
                {searchInputFilter}
              </Box>
            )}
            <Text
              alignSelf="center"
              fontFamily="FedraSansStd-book"
              fontSize="14px"
              fontWeight="bold"
              textAlign="left"
              color="#8a8173"
              float={{ base: 'inherit' }}
              lineHeight={{ base: 'inherit', sm: '3rem', xl: 'inherit' }}
            >
              FILTERS
              <Box
                as="span"
                float={
                  LangStyle[language]?.float
                    ? LangStyle[language].float
                    : 'none'
                }
              >
                :
              </Box>
            </Text>
            {locales?.length > 0 && (
              <Filter
                type="language"
                chakraProps={{
                  mr: '20px',
                }}
                ddOptions={locales
                  .sort((a, b) => a?.label.localeCompare(b?.label))
                  .map(val => {
                    return {
                      option: val.label ? val.label : '',
                      value: val.code,
                    };
                  })}
                placeholder={
                  selectedLanguage
                    ? selectedLanguage
                    : locales.find(lang =>
                      lang.code.toLowerCase().includes(language)
                    )?.label
                }
                onClick={data => {
                  setSelectedLanguage(data.label);
                  onLanguageChange(data);
                }}
              />
            )}
            {featuredTopics?.length > 0 && (
              <Filter
                type="topic"
                chakraProps={{
                  mr: '20px',
                }}
                ddOptions={generateTopicList()}
                placeholder="Topics"
                onClick={data => fetchDataHandler({ data })}
              />
            )}
            {contentTypes.length > 0 && (
              <Filter
                type="contentType"
                chakraProps={{
                  mr: '20px',
                }}
                ddOptions={contentTypes.map(type => {
                  return {
                    option: type.title,
                    value: type.urlAlias,
                  };
                })}
                placeholder="Content Type"
                onClick={data =>
                  fetchDataHandler({ data, searchKey: getSearchKey() })
                }
              />
            )}
          </Box>
          <Box
            display={{ base: 'block', xl: 'flex' }}
            m={{ base: '30px 0 0 0', md: '0 0 15px', xl: '0' }}
            ml={{
              base: '0',
              md: '0',
              xl: language == 'ar' ? '0' : '9%',
            }}
            mr={{ xl: language == 'ar' ? '7%' : '0' }}
            flexDirection={{
              base: 'column-reverse',
              sm: 'column-reverse',
              md: 'column',
              xl: LangStyle[language]?.flexRowRTL
                ? LangStyle[language].flexRowRTL
                : 'row',
            }}
            order={{ base: '1', xl: 'unset' }}
            w={{ base: '100%', xl: 'unset' }}
          >
            <Text
              alignSelf="center"
              fontFamily="FedraSansStd-book"
              fontSize="14px"
              fontWeight="bold"
              textAlign="left"
              color="#8a8173"
              order={{
                base: '2',
                sm: '2',
                md: '0',
                xl: '0',
              }}
              float={{ base: 'inherit' }}
              lineHeight={{ base: 'inherit', sm: '3rem', xl: 'inherit' }}
            >
              SORT BY
              <Box
                as="span"
                float={
                  LangStyle[language]?.float
                    ? LangStyle[language]?.float
                    : 'none'
                }
              >
                :
              </Box>
            </Text>
            <Filter
              type="sort_bef_combine"
              chakraProps={{
                mr: '20px',
                width: { base: 'auto', xl: 'auto' },
              }}
              /* prettier-ignore */
              ddOptions={[
                { option: 'newest', value: '' },
                { option: 'oldest', value: 'created ASC' },
              ].map((val) => {
                return {
                  option: val.option,
                  value: val.value,
                };
              })}
              placeholder={sort_bef_combine ? 'Oldest' : 'Newest'}
              onClick={data =>
                fetchDataHandler({ data, searchKey: getSearchKey() })
              }
            />
            {variant === 'large' && (
              <Box
                display={{
                  base: 'none',
                  lg: language == 'ar' ? 'none' : 'block',
                }}
              >
                {searchInputFilter}
              </Box>
            )}
          </Box>
        </Box>
        <Box p={{ base: '0px 4% 20px', md: '0px 3% 20px', xl: '0px 4% 20px' }}>
          {Object.keys(neededQueryParams)?.map(param => {
            const tag = neededQueryParams[param];
            const tagElement = tag ? (
              <Box
                borderRadius="30px !important"
                fontFamily="FedraSansStd-book"
                fontSize="12px"
                textAlign="center"
                color="#8a8173"
                margin="0.25vw"
                border="1px solid #dfd6c4"
                backgroundColor="#fff"
                fontWeight="inherit"
                display="inline-block"
                p="10px"
                textTransform="capitalize"
              >
                {tag}
                <Box
                  as="span"
                  pl="10px"
                  fontFamily="FedraSansStd-book"
                  fontSize="12px"
                  color="#8a8173"
                  cursor="pointer"
                  opacity="0.6"
                  textDecoration="none"
                  _hover={{
                    color: '#cf4520',
                  }}
                  onClick={() =>
                    deleteTag({ [param]: neededQueryParams[param] })
                  }
                >
                  X
                </Box>
              </Box>
            ) : (
              ''
            );
            return tagElement;
          })}
          {Object.values(neededQueryParams)?.length > 0 ? (
            <Text
              fontFamily="FedraSansStd-book"
              fontSize="12px"
              textAlign="center"
              margin="0.25vw"
              backgroundColor="#fff"
              fontWeight="inherit"
              display="inline-block"
              p="10px"
              color="#cf4520"
              cursor="pointer"
              _hover={{
                textDecoration: 'none',
              }}
              onClick={clearAllHandler}
            >
              Clear All
            </Text>
          ) : (
            ''
          )}
          {allTopics && (
            <AllTopicsModal
              modalTopics={modalTopics}
              popularTopics={popularTopics}
              isOverlay={true}
              closeModal={closeModal}
              urlpath={urlpath}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
