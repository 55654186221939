/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders Wisdom Banner Component 
 *
 * @param {string} title - Title of Component 
 * @param {string} smallSize - Size of Banner
 * @returns {ReactElement} Wisdom Banner Component
 */

/**
 *
 * @param root0
 * @param root0.title
 * @param root0.smallSize
 */
export default function WisdomBanner({ title = 'Wisdom', smallSize }) {
  return (
    <Box
      maxW={smallSize ? '1170px' : '1300px'}
      m="0 auto"
      p="10px 0 20px 0"
      height={{
        base: smallSize ? 'auto' : '131px',
        md: smallSize ? 'auto' : '200px',
      }}
      backgroundColor="#8C341C"
      bgImage={`${config.imgPath}/d/46272/1663639086-wisdom-header.jpg`}
      // bgImage="https://images.sadhguru.org/profiles/isha/themes/ishatheme/assets/wisdom-header.jpg"
      clipPath={smallSize ? 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)' : 'unset'}
    >
      <Text
        p={{
          base: smallSize ? '8vw 8vw' : '6vw 0',
          sm: smallSize ? '1vw 8vw' : '0',
          md: smallSize ? '1vw 8vw' : '2vw 0',
          lg: smallSize ? '1vw 8vw' : '2vw 0',
        }}
        textAlign={smallSize ? 'left' : 'center'}
        fontFamily="FedraSerifAStdBook"
        fontSize={{
          base: smallSize ? '30px' : '48px',
          sm: smallSize ? '30px' : '48px',
          md: smallSize ? '54px' : '81px',
        }}
        color="#eae3d4"
        textTransform="capitalize"
      >
        {title}
      </Text>
    </Box>
  );
}
