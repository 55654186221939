/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders the Featured Programs component
 *
 * @param {object} programs - data
 * @param {string} region - country
 * @param {string} lang - language
 * @returns {ReactElement} Featured Programs component
 */
const FeaturedPrograms = ({ programs, region, lang }) => {
  return (
    <Box
      background="#0b055f"
      clipPath={{
        base: 'polygon(0 2%, 100% 0, 100% 100%, 0% 100%)',
        md: 'polygon(0 7%, 100% 0, 100% 100%, 0% 100%)',
      }}
      paddingBottom="60px"
      className="clear-both"
    >
      <Box color="#28231e" textAlign="center">
        <Box
          fontSize={{ base: '24px', md: '30px' }}
          padding={{ base: '60px 0 10px 0', md: '150px 0 10px 0' }}
          color="#f7f5f2"
          fontFamily="FedraSansStd-A-medium"
        >
          Featured Programs
        </Box>
        <LazyLoadImageComponent
          alt=""
          title=""
          src={`${config.staticPath}/assets/images/flower-divider-horizontal-beige.svg`}
          pb={{ md: '38px' }}
          pt={{ base: '38px' }}
          w={{ base: '300px' }}
          transform={{ base: 'rotate(180deg)', lg: 'none' }}
        />

        <Box
          display="flex"
          flexFlow={{ base: 'column', md: 'row', sm: 'unset' }}
          flexWrap={{ sm: 'wrap !important', lg: 'nowrap !important' }}
          alignItems={{ base: 'center', sm: 'flex-start', md: 'flex-start' }}
          justifyContent={{ base: 'center', sm: 'space-around' }}
          textAlign="left"
          margin="0 auto"
          padding={{ md: '5%' }}
        >
          {/* dynamic binding */}
          {programs?.content?.map((item, index) => {
            return (
              <Box
                w={{ base: '93%', md: '58%', lg: '33%' }}
                margin={{
                  base: '0 0 30px 0',
                  md: '0 20px 50px 0',
                  lg: index === 2 ? '0' : '0 30px 15px 0',
                }}
                padding="0 0 20px 0"
                boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                minHeight={{ base: 'unset', lg: '385px' }}
                background="#ffffff"
                key={index}
              >
                <NextLink
                  href={refineUrlDomain(item.urlAlias, region, lang)}
                  passHref
                  prefetch={false}
                  legacyBehavior
                >
                  <Link
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Box>
                      <Box background="#ae4511">
                        <Box minH={{ base: '231px', sm: '411px', lg: '222px' }}>
                          <LazyLoadImageComponent
                            src={item?.image?.url}
                            alt={item?.image?.alt}
                            title={item?.image?.title}
                            clipPath="polygon(0 0, 100% 0, 100% 90%, 0% 98%)"
                          />
                        </Box>
                        <Text
                          fontFamily="FedraSansStd-A-medium"
                          fontSize={{
                            base: '24px',
                            md: '17px',
                            lg: '24px',
                          }}
                          padding="18px"
                          color="#ffffff"
                        >
                          {item.title}
                        </Text>
                      </Box>
                      <Text
                        fontSize={{ base: '17px', md: '13px', lg: '17px' }}
                        padding="18px"
                        maxHeight="80px"
                        lineHeight="20px"
                        className="truncate-text"
                        fontFamily="FedraSansStd-book"
                      >
                        {item.summary}
                      </Text>
                    </Box>
                  </Link>
                </NextLink>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedPrograms;
