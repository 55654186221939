/* Built In Imports */

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
import { getLatestBuild } from './commonService';

const isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText) => {
  let data = {};
  if (process.env?.NEXT_PUBLIC_BUILD_ID) {
    data = await getLatestBuild(process.env.NEXT_PUBLIC_BUILD_ID);
  } else if (config.BUILD_URLS?.LANDING_PAGES?.QuoteSubscription) {
    data = await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID || process.env.env_var_build_id
    );
  }
  const urlArr = filterBuildUrls(
    'landing_pages',
    level,
    url,
    notMatchText,
    data
  );
  return urlArr || [];
};
export const fetchDetails = async (region, lang, slug = ``, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  }
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `daily-quotes-subscription`,
        // region,
        lang,
      },
    }
  );
  return pageData;
};
