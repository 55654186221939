/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, Link, Text, VStack } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import parser from 'html-react-parser';

/* Internal Imports */
/* Components */
import LikeBookmark from '@components/Utility/LikeBookmark';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { LangStyle } from 'constants/index';

/* Services */
/**
 * Render the Quote Card Component
 *
 * @param {string} summary - Summary of the card card
 * @param {string} title - Title of the card
 * @param {string} createdAt - Date of Card Created at
 * @param {string} updatedAt - Date of Card was updated
 * @param {string} url - Url of the card
 * @param {string} postedOn - Date of card was on posted on
 * @returns {ReactElement} - QuoteCard Component
 */
export default function QuoteCard({
  cardImage,
  summary,
  title,
  createdAt,
  updatedAt,
  url = `/en/wisdom/quotes?date=september-24-2021`,
  postedOn,
  favorite,
  totalFavorite,
  bookmarked,
  uuid,
  isLiked,
  isBookmarked,
  reg,
  lang,
  updateCards,
  isShareEnabled = true,
}) {
  const [isShareSlideShow, setIsShareSlideShow] = useState(false);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  // const langFont = {
  //   hi: 'Mukta',
  //   ta: 'MuktaMalar',
  // };
  // const langFontSize = {
  //   hi: '22px',
  //   ta: '22px',
  // };
  // let date = new Date(createdAt).toLocaleDateString('en-US', options);
  const date = new Date(title).toLocaleDateString('en-US', options);

  const shareHandler = () => {
    setIsShareSlideShow(!isShareSlideShow);
  };

  return (
    <Box>
      <VStack
        m="0"
        mb="30px"
        // maxW={{ base: '100%' }}
        border="solid 5px #fff"
        boxShadow="0 0 16px 0 rgb(0 0 0 / 40%)"
        bgImage={`${config.imgPath}/d/46272/1663582814-quote_pattern-final.jpg`}
        //bgImage="https://images.sadhguru.org/profiles/isha/themes/ishatheme/assets/Quote_pattern-final.jpg"
        _hover={{
          boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)',
        }}
        maxW="370px"
      >
        <Box mt="3vw">
          <LazyLoadImageComponent
            alt="quotation mark"
            title="quotation mark"
            src={`${config.imgPath}/d/46272/1663582868-quotation-mark.svg`}
          />
        </Box>
        <Box m="0">
          <Text
            width="100%"
            //   height="160px"
            // fontFamily="FedraSerifAStdBook"

            fontWeight="normal"
            fontStretch="normal"
            fontStyle="normal"
            lineHeight="1.33"
            letterSpacing="normal"
            textAlign="center"
            padding="5%"
          >
            <NextLink
              href={refineUrlDomain(url, reg, lang)}
              passHref
              prefetch={false}
              legacyBehavior
            >
              <Link _hover={{ textDecoration: 'none' }}>
                <Box
                  fontFamily={
                    LangStyle[lang]?.fontFamily
                      ? LangStyle[lang].fontFamily
                      : 'FedraSerifAStdBook'
                  }
                  fontSize={
                    LangStyle[lang]?.fontSizeQuote
                      ? LangStyle[lang].fontSizeQuote
                      : '24px'
                  }
                  _focus={{ boxShadow: 'none' }}
                  minHeight="auto"
                  height="100%"
                  lineHeight="1.33"
                  color="#000054"
                  textOverflow="ellipsis"
                  cursor="pointer"
                >
                  {parser(summary)}
                </Box>
              </Link>
            </NextLink>
          </Text>
        </Box>
        <Box
          w="100%"
          pos="relative"
          p="10px 29px 20px 29px"
          color="#8a8173"
          fontFamily="FedraSansStd-medium"
          fontSize="12px"
        >
          <Box float="left" fontSize="14px">
            {date}
          </Box>
          {isShareEnabled && (
            <Box
              float="right"
              // width={{ base: '145px', lg: '180px' }}
              display="flex"
            >
              <LikeBookmark
                id={uuid}
                isLiked={isLiked}
                isBookmarked={isBookmarked}
                lang={lang}
                updateCards={updateCards}
              />
              <Box float="left" p="0 0.5vw">
                <Box
                  height="24px"
                  width="24px"
                  p="0 0.5vw"
                  float="right"
                  cursor="pointer"
                  pos="relative"
                  zIndex="1"
                  background={`url('${config.imgPath}/d/46272/1663579985-share.svg')`}
                  //background={`url(share.svg')`}
                  onClick={shareHandler}
                ></Box>
                <Box
                  overflow="hidden"
                  background="#ffffff"
                  position="absolute"
                  width="85%"
                  right="30px"
                  bottom="25px"
                  padding="4px 0 6px"
                  borderRadius="5px"
                  display={isShareSlideShow ? 'block' : 'none'}
                >
                  <Box height="30px">
                    <Text
                      as="span"
                      display="flex"
                      data-a2a-url={`${config.BASE_PATH}${url}`}
                      data-a2a-title="How to Cope with Job Loss during the Pandemic? | Sadhguru Answers"
                      // style="line-height: 32px;"
                    >
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580397-whatsapp.svg')`}
                          //background={`url(whatsapp.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580395-whatsapp1.svg')`,
                            /*background:
                              "url(whatsapp1.svg')",*/
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/facebook?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580393-facebook.svg')`}
                          //background="url(facebook.svg')"
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580389-facebook1.svg)`,
                            /*background:
                              "url(facebook1.svg')",*/
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/twitter?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580385-twitter.svg')`}
                          //background="url(twitter.svg')"
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580381-twitter1.svg)`,
                            /*background:
                              "url(twitter1.svg')",*/
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/linkedin?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580377-linkedin.svg')`}
                          //background="url(linkedin.svg')"
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580369-linkedin1.svg')`,
                            /*background:
                              "url(linkedin1.svg')",*/
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/email?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580357-message.svg')`}
                          //background="url(message.svg')"
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580355-message1.svg')`,
                            /*background:
                              "url(message1.svg')",*/
                          }}
                        ></Text>
                      </Link>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </VStack>
    </Box>
  );
}
