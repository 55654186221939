
/* Built In Imports */
import React, { useState } from 'react';

/* External Imports */
import {
  Box, ListItem, Text,
  UnorderedList, useOutsideClick
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 *
 * @param root0
 * @param root0.type
 * @param root0.chakraProps
 * @param root0.ddOptions
 * @param root0.placeholder
 * @param root0.onClick
 */
export default function Filter({
  type,
  chakraProps,
  ddOptions,
  placeholder,
  onClick,
}) {
  const ref = React.useRef();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const dropDownHandler = e => {
    setIsDropDownOpen(!isDropDownOpen);
    e.stopPropagation();
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsDropDownOpen(false),
  });

  return (
    <Box
      key={type}
      ref={ref}
      width="auto"
      display={{ base: 'block', md: 'inline-block' }}
      position="relative"
      {...chakraProps}
    >
      <Text as="span" position="relative">
        <Box
          position="relative"
          display={{ base: 'block', md: 'inline-block' }}
          verticalAlign="middle"
          onClick={dropDownHandler}
        >
          <Box
            padding="10px 0 !important"
            ml="15px !important"
            minWidth={{ base: '96%', md: '125px' }}
            borderWidth="0 0 1px 0 !important"
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            borderColor="#CF4523 !important"
            outline="unset"
            lineHeight="1.42857143"
            cursor="pointer"
            userSelect="none"
            _hover={{
              color: '#cf4520',
            }}
          >
            <Text as="span" color="#28231e" pl="5px">
              {placeholder}
            </Text>
            <Text
              as="span"
              background={`url('${config.imgPath}/d/46272/1663583568-down_arrow_black.svg') no-repeat`}
              //background="url(down_arrow_black.svg') no-repeat"
              backgroundSize="auto auto"
              width="14px"
              height="8px"
              position="absolute"
              // margin="0"
              // borderWidth="0 !important"
              float="right"
              right="0"
              mt="12px"
            ></Text>
          </Box>
          <Box position="absolute" zIndex="1" w={{ base: '100%', lg: 'auto' }}>
            <UnorderedList
              boxShadow="0 9px 12px 0 rgb(0 0 0 / 30%)"
              minWidth="128px"
              backgroundColor="#fff"
              maxWidth="100%"
              display={isDropDownOpen ? 'block' : 'none'}
              maxHeight="350px"
              overflow="auto"
            >
              {ddOptions.map((ddOption, i) => {
                return (
                  <ListItem
                    key={nanoid()}
                    tabIndex="0"
                    fontFamily="FedraSansStd-book"
                    fontSize="14px"
                    textDecoration="none"
                    padding="10px 15px 10px 20px"
                    color="#676055"
                    lineHeight="1.7"
                    textTransform="capitalize"
                    whiteSpace="nowrap"
                    _hover={{
                      backgroundColor: '#cf4520',
                      color: '#fff',
                    }}
                    cursor="pointer"
                    onClick={() => {
                      onClick({
                        type: type,
                        option: ddOption.option,
                        value: ddOption.value,
                      });
                    }}
                  >
                    {ddOption.option}
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>
        </Box>
      </Text>
    </Box>
  );
}
